import FullScreenPresenter from '@theme/components/utils/FullScreenPresenter'

export default {
  name: 'BranchSelectorBalikovna',
  components: { FullScreenPresenter },
  props: {
    shipper: Object,
  },
  computed: {
    balikovnaOptions() {
      return {
        type: this.shipper.type.endsWith('NB') ? 'BALIKOVNA' : 'POST_OFFICE',
        phone: false,
        skipLocation: false,
      }
    },
    balikovnaUrl() {
      const params = new URLSearchParams()

      for (const balikovnaOptionKey in this.balikovnaOptions) {
        params.append(balikovnaOptionKey, this.balikovnaOptions[balikovnaOptionKey])
      }

      return 'https://b2c.cpost.cz/locations/?' + params.toString()
    },
  },
  mounted() {
    this.onMounted()
  },
  destroyed() {
    this.onDestroyed()
  },
  methods: {
    onMounted() {
      window.addEventListener('message', this.onPostMessage)
    },
    onDestroyed() {
      window.removeEventListener('message', this.onPostMessage)
    },
    async onBranchChange(id) {
      const clearedId = id.replace('B', '').replace('P', '') // CP puts 'B' or 'P'
      const branch = await this.$store.dispatch('cart/GET_STORE_BRANCH_BY_REMOTE_ID', {
        shipperId: this.shipper.id,
        remoteId: Number(clearedId),
      })
      this.$store.commit('cart/SET_BRANCH', branch)
      this.hide()
    },
    onPostMessage(event) {
      const data = event?.data || null
      const message = data?.message || null

      if (message === 'pickerResult') {
        const point = data?.point
        this.onBranchChange(point?.id)
      }
    },

    open() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
  },
}
